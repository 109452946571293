import React, { useState, useEffect } from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const DirectedWorks = ({ formData = [], handleAddField, onSave }) => {
  const [jsonInput, setJsonInput] = useState('');
  const [data, setData] = useState(formData);

  useEffect(() => {
    setData(formData);
  }, [formData]);

  const handleChange = (section, key, value, index) => {
    setData((prevData) => {
      const updatedSection = [...prevData];
      updatedSection[index] = {
        ...updatedSection[index],
        [key]: value
      };
      return updatedSection;
    });
  };

  const handleJsonInput = (event) => {
    setJsonInput(event.target.value);
  };

  const handleProcessJson = () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      if (Array.isArray(parsedData)) {
        setData(parsedData);
      } else {
        alert('JSON inválido. Por favor, asegúrate de que sea un arreglo de objetos.');
      }
    } catch (error) {
      console.error('Invalid JSON', error);
      alert('JSON inválido. Por favor, verifica la sintaxis.');
    }
  };

  const handleSaveSection = () => {
    onSave('trabajos_dirigidos_tutorias', data);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Trabajos Dirigidos/Tutorías
        </Typography>
        <Divider />
      </Grid>
      {data.map((work, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tipo"
              value={work?.tipo || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'tipo', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Título"
              value={work?.titulo || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'titulo', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Institución"
              value={work?.institucion || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'institucion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Estado (Tesis en curso/ Tesis concluida)"
              value={work?.estado || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'estado', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Especialidad"
              value={work?.especialidad || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'especialidad', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={work?.fecha || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'fecha', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Rol (Tutor principal)"
              value={work?.rol || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'rol', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Orientados"
              value={work?.orientados?.join(', ') || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'orientados', e.target.value.split(', '), index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tutores"
              value={work?.tutores?.join(', ') || ''}
              onChange={(e) => handleChange('trabajos_dirigidos_tutorias', 'tutores', e.target.value.split(', '), index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('trabajos_dirigidos_tutorias')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={6}
          label="Trabajos Dirigidos/Tutorías JSON"
          value={jsonInput}
          onChange={handleJsonInput}
          placeholder="Ingresa el JSON aquí"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleProcessJson}>
          Procesar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSaveSection}>
          Guardar Trabajos Dirigidos/Tutorías
        </Button>
      </Grid>
    </>
  );
};

export default DirectedWorks;
