// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Detecta automáticamente el idioma del navegador
  .use(initReactI18next) // Integración con React
  .init({
    resources: {
      en: {
        translation: {
            "home": "Home",
            "about": "About",
            "services": "Services",
            "contact": "Contact",
            "login": "Login",
            "addResearcher": "Add Researcher",
            "logout": "Logout",
            "meetOurResearchers": "Meet Our Researchers"
          }
          
      },
      es: {
        translation: {
            "home": "Inicio",
            "about": "Acerca de",
            "services": "Servicios",
            "contact": "Contacto",
            "login": "Iniciar sesión",
            "addResearcher": "Agregar Investigador",
            "logout": "Cerrar sesión",
            "meetOurResearchers": "Conoce a nuestros investigadores"
          }
      }
    },
    fallbackLng: 'en', // Idioma por defecto si el detectado no está soportado
    debug: true, // Desactívalo en producción
    interpolation: {
      escapeValue: false, // React ya hace el escapado por seguridad
    }
  });

export default i18n;
