import React from 'react';
import { Grid, TextField, Button, Divider, Typography } from '@mui/material';

const AuthorIdentifiers = ({ formData, handleChange, handleSaveSection }) => {
  const data = formData || {};

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Identificadores de Autor
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Autor ID (Scopus)"
          value={data.autor_id_scopus || ''}
          onChange={(e) => handleChange('identificadores_de_autor', 'autor_id_scopus', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="ORCID"
          value={data.orcid || ''}
          onChange={(e) => handleChange('identificadores_de_autor', 'orcid', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('identificadores_de_autor')}>
          Guardar Identificadores de Autor
        </Button>
      </Grid>
    </>
  );
};

export default AuthorIdentifiers;
