import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPath } from './store';
import Banner from './shared/Banner';

const NavigationComponent = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = useSelector((state) => state.navigation.currentPath);
  const researchers = useSelector((state) => state.researchers.list);

  useEffect(() => {
    dispatch(setPath(location.pathname));
  }, [location, dispatch]);

  const getTitle = () => {
    const researcher = researchers.find(r => `/researcher/${r.id}` === location.pathname);
    if (researcher) {
      return `Researcher: ${researcher.informacion_personal.nombre_completo}`;
    }
    switch (currentPath) {
      case '/about':
        return 'About';
      case '/services':
        return 'Services';
      case '/contact':
        return 'Contact';
      case '/login':
        return 'Login';
      case '/add-researcher':
        return 'Add Researcher';
      default:
        return '';
    }
  };

  return (
    <div>
      {currentPath === '/' ? (
        <img
          src="https://picsum.photos/800/300?random=1"
          alt="Static Banner"
          style={{ width: '100%', height: '350px', objectFit: 'cover' }}
        />
      ) : (
        <Banner title={getTitle()} />
      )}
      <div style={{ paddingTop: currentPath === '/' ? '0px' : '100px' }}>
        {children}
      </div>
    </div>
  );
};

export default NavigationComponent;
