import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ResearcherCard from '../../components/ResearcherCard';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchResearchers } from '../../store';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const dispatch = useDispatch();
  const researchers = useSelector((state) => state.researchers.list);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const platform = isMobile ? (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad') ? 'ios' : 'android') : null;
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchResearchers());
  }, [dispatch]);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {t('meetOurResearchers')}
      </Typography>
      <Grid container spacing={2}>
        {researchers.map((researcher) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={researcher.id}>
            <ResearcherCard
              id={researcher.id}
              informacion_personal={researcher.informacion_personal}
              titulo={researcher.direccion_profesional.institucion} // o cualquier otro campo que quieras mostrar
              resumen={researcher.direccion_profesional.email_institucional} // o cualquier otro campo que quieras mostrar
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Home;
