import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ResearchLines = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const data = formData || [];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Líneas de Investigación
        </Typography>
        <Divider />
      </Grid>
      {data.map((line, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Línea de Investigación"
              value={line.linea_investigacion || ''}
              onChange={(e) => handleChange('lineas_investigacion', 'linea_investigacion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Activa (Sí/No)"
              value={line.activa || ''}
              onChange={(e) => handleChange('lineas_investigacion', 'activa', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('lineas_investigacion')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('lineas_investigacion')}>
          Guardar Líneas de Investigación
        </Button>
      </Grid>
    </>
  );
};

export default ResearchLines;
