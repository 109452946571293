import React from 'react';
import { Box, Typography, Grid, Card, CardContent, createTheme, ThemeProvider } from '@mui/material';
import { styled } from '@mui/system';

// Define the theme with a secondary color
const theme = createTheme({
    palette: {
        secondary: {
            main: '#4C3BCF', // Replace this with your secondary color
        },
    },
});

// Styled components for hover and shadow effects
const StyledCard = styled(Card)(({ theme }) => ({
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
        boxShadow: theme.shadows[6],
    },
}));

const TableHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'uppercase',
}));

const GeneralInfo = ({ researcher }) => {
    return (
        <ThemeProvider theme={theme}>
            <Box className="page-content" sx={{ padding: 2 }}>
                <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>Información General</Typography>

                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} md={6}>
                        <StyledCard>
                            <CardContent>
                                <TableHeader variant="h6" gutterBottom>Información Personal</TableHeader>
                                <Typography variant="body1"><strong>Documento de identidad:</strong> {researcher.informacion_personal.documento_identidad}</Typography>
                                <Typography variant="body1"><strong>Nombre en citaciones:</strong> {researcher.informacion_personal.nombre_en_citaciones}</Typography>
                                <Typography variant="body1"><strong>Sexo:</strong> {researcher.informacion_personal.sexo}</Typography>
                                <Typography variant="body1"><strong>Nacionalidad:</strong> {researcher.informacion_personal.nacionalidad}</Typography>
                                <Typography variant="body1"><strong>Fecha de nacimiento:</strong> {researcher.informacion_personal.fecha_nacimiento}</Typography>
                                <Typography variant="body1"><strong>Lugar de nacimiento:</strong> {researcher.informacion_personal.lugar_nacimiento}</Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledCard>
                            <CardContent>
                                <TableHeader variant="h6" gutterBottom>Redes Sociales Académicas</TableHeader>
                                <Typography variant="body1"><strong>Google Scholar:</strong> {researcher.redes_sociales_academicas.google_scholar || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>ResearchGate:</strong> {researcher.redes_sociales_academicas.researchgate || 'No hay datos'}</Typography>
                            </CardContent>
                        </StyledCard>
                        <StyledCard sx={{ marginTop: 2 }}>
                            <CardContent>
                                <TableHeader variant="h6" gutterBottom>Identificadores de Autor</TableHeader>
                                <Typography variant="body1"><strong>Autor ID (Scopus):</strong> {researcher.identificadores_de_autor.autor_id_scopus || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>ORCID:</strong> {researcher.identificadores_de_autor.orcid || 'No hay datos'}</Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledCard>
                            <CardContent>
                                <TableHeader variant="h6" gutterBottom>Dirección Profesional</TableHeader>
                                <Typography variant="body1"><strong>Institución:</strong> {researcher.direccion_profesional.institucion || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>Dirección profesional:</strong> {researcher.direccion_profesional.direccion_profesional || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>Barrio profesional:</strong> {researcher.direccion_profesional.barrio_profesional || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>Teléfono profesional:</strong> {researcher.direccion_profesional.telefono_profesional || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>Email institucional:</strong> {researcher.direccion_profesional.email_institucional || 'No hay datos'}</Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledCard>
                            <CardContent>
                                <TableHeader variant="h6" gutterBottom>Dirección Residencial</TableHeader>
                                <Typography variant="body1"><strong>Dirección residencial:</strong> {researcher.direccion_residencial.direccion_residencial || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>Barrio residencial:</strong> {researcher.direccion_residencial.barrio_residencial || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>Municipio:</strong> {researcher.direccion_residencial.municipio || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>Teléfono residencial:</strong> {researcher.direccion_residencial.telefono_residencial || 'No hay datos'}</Typography>
                                <Typography variant="body1"><strong>Email personal:</strong> {researcher.direccion_residencial.email_personal || 'No hay datos'}</Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default GeneralInfo;
