import React from 'react';
import { Grid, TextField, Button, Divider, Typography } from '@mui/material';

const PersonalInformation = ({ formData, handleChange, handleFileChange, handleSaveSection }) => {
  const data = formData || {};

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Información Personal
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Nombre Completo"
          value={data.nombre_completo || ''}
          onChange={(e) => handleChange('informacion_personal', 'nombre_completo', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Nombre en Citaciones"
          value={data.nombre_en_citaciones || ''}
          onChange={(e) => handleChange('informacion_personal', 'nombre_en_citaciones', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Documento de Identidad"
          value={data.documento_identidad || ''}
          onChange={(e) => handleChange('informacion_personal', 'documento_identidad', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Nacionalidad"
          value={data.nacionalidad || ''}
          onChange={(e) => handleChange('informacion_personal', 'nacionalidad', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Fecha de Nacimiento"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={data.fecha_nacimiento || ''}
          onChange={(e) => handleChange('informacion_personal', 'fecha_nacimiento', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Lugar de Nacimiento"
          value={data.lugar_nacimiento || ''}
          onChange={(e) => handleChange('informacion_personal', 'lugar_nacimiento', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Sexo"
          value={data.sexo || ''}
          onChange={(e) => handleChange('informacion_personal', 'sexo', e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" component="label">
          Upload Photo
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('informacion_personal')}>
          Guardar Información Personal
        </Button>
      </Grid>
    </>
  );
};

export default PersonalInformation;
