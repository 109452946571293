import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Box, IconButton, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2),
  borderRadius: '12px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  width: '100%',
  maxWidth: 345,
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1),
  },
}));

const StyledCardMedia = styled(Avatar)({
  width: 140,
  height: 140,
  margin: '0 auto 16px',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.2)',
  },
});

const SocialIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1),
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
}));

const ResearcherCard = ({ id, informacion_personal, titulo, resumen }) => {
  const navigate = useNavigate();
  const placeholderImage = 'https://picsum.photos/140'; // URL de la imagen de placeholder

  const handleCardClick = () => {
    navigate(`/researcher/${id}`);
  };

  return (
    <StyledCard onClick={handleCardClick}>
      <StyledCardMedia 
        src={informacion_personal?.foto || placeholderImage} 
        alt={informacion_personal?.nombre_completo || 'Placeholder Name'} 
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: 'Roboto Condensed, Arial', textAlign: 'center' }}>
          {informacion_personal?.nombre_completo || 'Nombre Desconocido'}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ fontFamily: 'Montserrat, Arial', textAlign: 'center' }}>
          {titulo || 'Título Desconocido'}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ marginTop: '10px', fontFamily: 'Montserrat, Arial', textAlign: 'center' }}>
          {resumen || 'Resumen no disponible'}
        </Typography>
      </CardContent>
      <SocialIcons>
        <IconButton aria-label="Facebook" href="https://www.facebook.com" target="_blank" sx={{ color: '#FFFFFF' }}>
          <FaFacebookF />
        </IconButton>
        <IconButton aria-label="Twitter" href="https://www.twitter.com" target="_blank" sx={{ color: '#FFFFFF' }}>
          <FaTwitter />
        </IconButton>
        <IconButton aria-label="Instagram" href="https://www.instagram.com" target="_blank" sx={{ color: '#FFFFFF' }}>
          <FaInstagram />
        </IconButton>
      </SocialIcons>
    </StyledCard>
  );
};

export default ResearcherCard;
