import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Avatar, Grid, CircularProgress, Tab, Tabs, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fetchResearchers } from '../store';
import { normalizeResearcher } from '../services/utils';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './ResearcherDetails.css'; // Importa el archivo CSS para las animaciones
import TrabajosDirigidosTutorias from './TrabajosDirigidosTutorias'; // Importa el nuevo componente
import ProduccionBibliografica from './ProduccionBibliografica'; // Importa el nuevo componente
import EventosCientificos from './EventosCientificos'; // Importa el nuevo componente
import GeneralInfo from './GeneralInfo'; // Importa el nuevo componente

const ResearcherDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const researchers = useSelector((state) => state.researchers.list);
    const status = useSelector((state) => state.researchers.status);
    const [tabIndex, setTabIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchResearchers());
        }
    }, [status, dispatch]);

    const researcher = researchers.find(r => r.id === id);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        setMobileOpen(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    if (status === 'loading') {
        return <CircularProgress />;
    }

    if (!researcher) {
        return <Typography variant="h6">Researcher not found</Typography>;
    }

    const normalizedResearcher = normalizeResearcher(researcher);

    const renderContent = () => {
        switch (tabIndex) {
            case 0:
                return <GeneralInfo researcher={normalizedResearcher} />;
            case 1:
                return (
                    <Box className="page-content" sx={{ padding: 2 }}>
                        <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f9f9f9', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#f9f9f9' } }}>Experiencia Profesional</Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                            {normalizedResearcher.experiencia_profesional.length > 0 ? (
                                normalizedResearcher.experiencia_profesional.map((experience, index) => (
                                    <div key={index}>
                                        <strong>{experience.institucion}</strong> - {experience.dedicacion}<br />
                                        <strong>Fecha:</strong> {experience.fecha_inicio} - {experience.fecha_fin}
                                    </div>
                                ))
                            ) : (
                                <div>No hay datos</div>
                            )}
                        </Typography>
                    </Box>
                );
            case 2:
                return (
                    <Box className="page-content" sx={{ padding: 2 }}>
                        <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>Áreas de Actuación</Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                            {normalizedResearcher.areas_actuacion.length > 0 ? (
                                normalizedResearcher.areas_actuacion.map((area, index) => (
                                    <div key={index}>{area.area}</div>
                                ))
                            ) : (
                                <div>No hay datos</div>
                            )}
                        </Typography>
                    </Box>
                );
            case 3:
                return (
                    <Box className="page-content" sx={{ padding: 2 }}>
                        <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>Idiomas</Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                            {normalizedResearcher.idiomas.length > 0 ? (
                                normalizedResearcher.idiomas.map((language, index) => (
                                    <div key={index}>
                                        <strong>{language.idioma}</strong><br />
                                        Habla: {language.habla}, Escribe: {language.escribe}, Lee: {language.lee}, Entiende: {language.entiende}
                                    </div>
                                ))
                            ) : (
                                <div>No hay datos</div>
                            )}
                        </Typography>
                    </Box>
                );
            case 4:
                return (
                    <Box className="page-content" sx={{ padding: 2 }}>
                        <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>Líneas de Investigación</Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                            {normalizedResearcher.lineas_investigacion.length > 0 ? (
                                normalizedResearcher.lineas_investigacion.map((line, index) => (
                                    <div key={index}>
                                        <strong>{line.linea_investigacion}</strong><br />
                                        Activa: {line.activa}
                                    </div>
                                ))
                            ) : (
                                <div>No hay datos</div>
                            )}
                        </Typography>
                    </Box>
                );
            case 5:
                return (
                    <Box className="page-content" sx={{ padding: 2 }}>
                        <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>Reconocimientos</Typography>
                        <Typography variant="body1" sx={{ marginTop: 2, lineHeight: 1.6 }}>
                            {normalizedResearcher.reconocimientos.length > 0 ? (
                                normalizedResearcher.reconocimientos.map((recognition, index) => (
                                    <div key={index}>
                                        <strong>{recognition.nombre_reconocimiento}</strong><br />
                                        Fecha: {recognition.fecha}
                                    </div>
                                ))
                            ) : (
                                <div>No hay datos</div>
                            )}
                        </Typography>
                    </Box>
                );
            case 6:
                return (
                    <TrabajosDirigidosTutorias
                        normalizedResearcher={normalizedResearcher}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                    />
                );
            case 7:
                return (
                    <EventosCientificos
                        eventos={normalizedResearcher.eventos_cientificos}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                    />
                );
            case 8:
                return (
                    <ProduccionBibliografica
                        productions={normalizedResearcher.produccion_bibliografica}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                        <Avatar
                            src={normalizedResearcher.informacion_personal.foto}
                            alt={normalizedResearcher.informacion_personal.nombre_completo}
                            sx={{
                                width: 250,
                                height: 250,
                                margin: '0 auto 16px',
                                boxShadow: 3,
                                '&:hover': {
                                    boxShadow: 6,
                                },
                                transition: 'box-shadow 0.3s ease-in-out',
                            }}
                        />
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                mt: 1,
                                backgroundColor: '#4C3BCF',
                                color: 'white',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                display: 'inline-block'
                            }}
                        >
                            {normalizedResearcher.informacion_personal.nombre_completo}
                        </Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Tabs
                            orientation="vertical"
                            value={tabIndex}
                            onChange={handleTabChange}
                            sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {['Información General', 'Experiencia Profesional', 'Áreas de Actuación', 'Idiomas', 'Líneas de Investigación', 'Reconocimientos', 'Trabajos Dirigidos/Tutorías', 'Eventos Científicos', 'Producción Bibliográfica'].map((label, index) => (
                                <Tab
                                    key={index}
                                    label={label}
                                    sx={{
                                        alignItems: 'flex-start',
                                        padding: '8px 16px',
                                        '&:hover': { backgroundColor: '#f0f0f0' },
                                        ...(tabIndex === index && { backgroundColor: '#d0d0d0', fontWeight: 'bold' })
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Box>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <Accordion expanded={mobileOpen}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                onClick={() => setMobileOpen(!mobileOpen)}
                            >
                                <Typography>Pestañas</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Tabs
                                    orientation="vertical"
                                    value={tabIndex}
                                    onChange={handleTabChange}
                                    sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                >
                                    {['Información General', 'Experiencia Profesional', 'Áreas de Actuación', 'Idiomas', 'Líneas de Investigación', 'Reconocimientos', 'Trabajos Dirigidos/Tutorías', 'Eventos Científicos', 'Producción Bibliográfica'].map((label, index) => (
                                        <Tab
                                            key={index}
                                            label={label}
                                            sx={{
                                                alignItems: 'flex-start',
                                                padding: '8px 16px',
                                                '&:hover': { backgroundColor: '#f0f0f0' },
                                                ...(tabIndex === index && { backgroundColor: '#d0d0d0', fontWeight: 'bold' })
                                            }}
                                        />
                                    ))}
                                </Tabs>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box sx={{ position: 'relative', overflow: 'hidden', maxHeight: '700px', overflowY: 'auto' }}>
                        <TransitionGroup>
                            <CSSTransition
                                key={tabIndex}
                                timeout={300}
                                classNames="page"
                            >
                                {renderContent()}
                            </CSSTransition>
                        </TransitionGroup>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ResearcherDetails;
