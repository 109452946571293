import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const BibliographicProduction = ({ formData, handleChange, handleAddField, handleSaveSection, jsonInput, handleJsonInput, handleProcessJson }) => {
  const data = formData || [];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Producción Bibliográfica
        </Typography>
        <Divider />
      </Grid>
      {data.map((production, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tipo de Producción"
              value={production.tipo_produccion || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'tipo_produccion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Título del Artículo"
              value={production.titulo_articulo || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'titulo_articulo', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Revista"
              value={production.revista || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'revista', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="ISSN"
              value={production.issn || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'issn', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Editorial"
              value={production.editorial || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'editorial', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Volumen"
              value={production.volumen || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'volumen', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Número"
              value={production.numero || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'numero', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Páginas"
              value={production.paginas || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'paginas', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={production.fecha || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'fecha', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="DOI"
              value={production.doi || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'doi', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="URL del Artículo (debe terminar en .pdf)"
              helperText="Debe ser una URL válida que termine en .pdf"
              value={production.url_articulo || ''}
              onChange={(e) => handleChange('produccion_bibliografica', 'url_articulo', e.target.value, index)}
              error={production.url_articulo && !/\.pdf$/.test(production.url_articulo)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('produccion_bibliografica')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={6}
          label="Producción Bibliográfica JSON"
          value={jsonInput}
          onChange={handleJsonInput}
          placeholder='Ingresa el JSON aquí'
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleProcessJson}>
          Procesar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('produccion_bibliografica')}>
          Guardar Producción Bibliográfica
        </Button>
      </Grid>
    </>
  );
};

export default BibliographicProduction;
