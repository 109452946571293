import React from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useTranslation } from 'react-i18next';

const TopHeader = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <AppBar position="static" sx={{ height: { xs: 'auto', md: '40px' }, justifyContent: 'center' }}>
      <Toolbar
        sx={{
          minHeight: '40px',
          background: 'linear-gradient(120deg, #402E7A 30%, #3DC2EC 30%, #3DC2EC 100%)',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center'
        }}
      >
        <Typography variant="body1" sx={{ flexGrow: 1, textAlign: { xs: 'center', md: 'left' } }}>
          Modern & Professional
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, width: { xs: '100%', md: 'auto' } }}>
          <IconButton
            aria-label="Facebook"
            href="https://www.facebook.com"
            target="_blank"
            sx={{ color: 'white', p: 0.5 }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            aria-label="Twitter"
            href="https://www.twitter.com"
            target="_blank"
            sx={{ color: 'white', p: 0.5 }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            aria-label="Instagram"
            href="https://www.instagram.com"
            target="_blank"
            sx={{ color: 'white', p: 0.5 }}
          >
            <InstagramIcon />
          </IconButton>
          <Button onClick={() => changeLanguage('en')} sx={{ color: 'white', p: 0.5 }}>
            English
          </Button>
          <Button onClick={() => changeLanguage('es')} sx={{ color: 'white', p: 0.5 }}>
            Español
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopHeader;
