import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ProfessionalExperience = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const data = formData || [];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Experiencia Profesional
        </Typography>
        <Divider />
      </Grid>
      {data.map((experience, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Institución"
              value={experience.institucion || ''}
              onChange={(e) => handleChange('experiencia_profesional', 'institucion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Dedicación (Horas Semanales)"
              value={experience.dedicacion || ''}
              onChange={(e) => handleChange('experiencia_profesional', 'dedicacion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Inicio"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={experience.fecha_inicio || ''}
              onChange={(e) => handleChange('experiencia_profesional', 'fecha_inicio', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Fin"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={experience.fecha_fin || ''}
              onChange={(e) => handleChange('experiencia_profesional', 'fecha_fin', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('experiencia_profesional')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('experiencia_profesional')}>
          Guardar Experiencia Profesional
        </Button>
      </Grid>
    </>
  );
};

export default ProfessionalExperience;
