import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Paper, Grid, Typography, IconButton, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import { fetchResearchers, addResearcher, updateResearcher, deleteResearcher } from '../store';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonalInformation from './Formulario/PersonalInformation';
import SocialNetworks from './Formulario/SocialNetworks';
import AuthorIdentifiers from './Formulario/AuthorIdentifiers';
import AcademicFormation from './Formulario/AcademicFormation';
import ProfessionalExperience from './Formulario/ProfessionalExperience';
import ActionAreas from './Formulario/ActionAreas';
import Languages from './Formulario/Languages';
import ResearchLines from './Formulario/ResearchLines';
import Recognitions from './Formulario/Recognitions';
import DirectedWorks from './Formulario/DirectedWorks';
import ScientificEvents from './Formulario/ScientificEvents';
import BibliographicProduction from './Formulario/BibliographicProduction';

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    width: '80%',
    margin: 'auto',
  },
}));

const TabsBar = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up('md')]: {
    width: '100%', // Ajustar el ancho de la barra de pestañas
  },
}));

const initialFormData = {
  informacion_personal: {
    nombre_completo: '',
    nombre_en_citaciones: '',
    documento_identidad: '',
    nacionalidad: '',
    fecha_nacimiento: '',
    lugar_nacimiento: '',
    sexo: '',
    foto: null,
  },
  redes_sociales_academicas: {
    google_scholar: '',
    researchgate: '',
  },
  identificadores_de_autor: {
    autor_id_scopus: '',
    orcid: '',
  },
  formacion_academica: [],
  experiencia_profesional: [],
  areas_actuacion: [],
  idiomas: [],
  lineas_investigacion: [],
  reconocimientos: [],
  trabajos_dirigidos_tutorias: [],
  eventos_cientificos: [],
  produccion_bibliografica: [],
};

const ResearcherForm = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [jsonInput, setJsonInput] = useState('');
  const [selectedResearcherId, setSelectedResearcherId] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // Estado para controlar el índice de la pestaña seleccionada
  const dispatch = useDispatch();
  const researchers = useSelector((state) => state.researchers.list);
  const status = useSelector((state) => state.researchers.status);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchResearchers());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const storedData = localStorage.getItem('researcherFormData');
    if (storedData) {
      setFormData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('researcherFormData', JSON.stringify(formData));
  }, [formData]);

  const handleChange = (section, field, value, index = null) => {
    setFormData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)); // Deep copy
      if (!newData[section]) {
        console.error(`Section ${section} does not exist in formData`);
        return prevData;
      }
      if (index !== null) {
        if (!newData[section][index]) {
          console.error(`Index ${index} does not exist in section ${section}`);
          return prevData;
        }
        newData[section][index][field] = value;
      } else {
        newData[section][field] = value;
      }
      return newData;
    });
  };

  const handleAddField = (section) => {
    setFormData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)); // Deep copy
      if (!newData[section]) {
        console.error(`Section ${section} does not exist in formData`);
        return prevData;
      }
      newData[section].push({});
      return newData;
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      informacion_personal: {
        ...prevData.informacion_personal,
        foto: file,
      },
    }));
  };

  const handleSaveSection = (sectionName, updatedData) => {
    const updatedSection = { [sectionName]: updatedData };

    if (selectedResearcherId) {
      dispatch(updateResearcher({ id: selectedResearcherId, updatedSection }));
    } else {
      dispatch(addResearcher(updatedSection));
    }
  };

  const handleEdit = (researcher) => {
    setSelectedResearcherId(researcher.id);
    setFormData(JSON.parse(JSON.stringify(researcher))); // Deep copy
  };

  const handleDelete = (id) => {
    dispatch(deleteResearcher(id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedResearcherId) {
      dispatch(updateResearcher({ id: selectedResearcherId, updatedResearcher: formData }));
    } else {
      dispatch(addResearcher(formData));
    }
    setFormData(initialFormData);
    setSelectedResearcherId(null);
  };

  const handleJsonInput = (e) => {
    setJsonInput(e.target.value);
  };

  const handleProcessJson = (section) => {
    try {
      const newEntries = JSON.parse(jsonInput);
      if (Array.isArray(newEntries)) {
        setFormData((prevData) => ({
          ...prevData,
          [section]: [...prevData[section], ...newEntries],
        }));
      } else {
        throw new Error('El JSON debe ser un array.');
      }
    } catch (error) {
      console.error('Error al procesar JSON: ', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <FormContainer>
      <Typography variant="h4" gutterBottom>
        Add New Researcher
      </Typography>

      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Investigadores</Typography>
        {researchers.map((researcher) => (
          <Box
            key={researcher.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 1,
              border: '1px solid #ddd',
              borderRadius: 1,
              marginBottom: 1,
            }}
          >
            <Typography>{researcher.informacion_personal.nombre_completo}</Typography>
            <Box>
              <IconButton onClick={() => handleEdit(researcher)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(researcher.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      <TabsBar
        value={tabIndex}
        onChange={handleTabChange}
        variant="scrollable" // Habilita el scroll
        scrollButtons="auto" // Muestra los botones de desplazamiento automáticamente
        aria-label="Tabs for researcher form"
      >
        <Tab label="Información Personal" />
        <Tab label="Redes Sociales" />
        <Tab label="Identificadores de Autor" />
        <Tab label="Formación Académica" />
        <Tab label="Experiencia Profesional" />
        <Tab label="Áreas de Actuación" />
        <Tab label="Idiomas" />
        <Tab label="Líneas de Investigación" />
        <Tab label="Reconocimientos" />
        <Tab label="Trabajos Dirigidos" />
        <Tab label="Eventos Científicos" />
        <Tab label="Producción Bibliográfica" />
      </TabsBar>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {tabIndex === 0 && (
            <PersonalInformation
              formData={formData.informacion_personal}
              handleChange={handleChange}
              handleFileChange={handleFileChange}
              handleSaveSection={handleSaveSection}
            />
          )}
          {tabIndex === 1 && (
            <SocialNetworks
              formData={formData.redes_sociales_academicas}
              handleChange={handleChange}
              handleSaveSection={handleSaveSection}
            />
          )}
          {tabIndex === 2 && (
            <AuthorIdentifiers
              formData={formData.identificadores_de_autor}
              handleChange={handleChange}
              handleSaveSection={handleSaveSection}
            />
          )}
          {tabIndex === 3 && (
            <AcademicFormation
            formData={formData.formacion_academica}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
          />
        )}
        {tabIndex === 4 && (
          <ProfessionalExperience
            formData={formData.experiencia_profesional}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
          />
        )}
        {tabIndex === 5 && (
          <ActionAreas
            formData={formData.areas_actuacion}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
          />
        )}
        {tabIndex === 6 && (
          <Languages
            formData={formData.idiomas}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
          />
        )}
        {tabIndex === 7 && (
          <ResearchLines
            formData={formData.lineas_investigacion}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
          />
        )}
        {tabIndex === 8 && (
          <Recognitions
            formData={formData.reconocimientos}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
          />
        )}
        {tabIndex === 9 && (
          <DirectedWorks
            formData={formData.trabajos_dirigidos_tutorias}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
            jsonInput={jsonInput}
            handleJsonInput={handleJsonInput}
            handleProcessJson={handleProcessJson}
          />
        )}
        {tabIndex === 10 && (
          <ScientificEvents
            formData={formData.eventos_cientificos}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
            jsonInput={jsonInput}
            handleJsonInput={handleJsonInput}
            handleProcessJson={handleProcessJson}
          />
        )}
        {tabIndex === 11 && (
          <BibliographicProduction
            formData={formData.produccion_bibliografica}
            handleChange={handleChange}
            handleAddField={handleAddField}
            handleSaveSection={handleSaveSection}
            jsonInput={jsonInput}
            handleJsonInput={handleJsonInput}
            handleProcessJson={handleProcessJson}
          />
        )}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            {selectedResearcherId ? 'Update Researcher' : 'Submit'}
          </Button>
        </Grid>
      </Grid>
    </form>
  </FormContainer>
);
};

export default ResearcherForm;

