// src/pages/About.js

import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About</h1>
      <p>About us page content goes here.</p>
    </div>
  );
};

export default About;
