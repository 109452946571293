import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AcademicFormation = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const data = formData || [];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Formación Académica
        </Typography>
        <Divider />
      </Grid>
      {data.map((academic, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nivel Académico"
              value={academic.nivel_academico || ''}
              onChange={(e) => handleChange('formacion_academica', 'nivel_academico', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Institución"
              value={academic.institucion || ''}
              onChange={(e) => handleChange('formacion_academica', 'institucion', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Programa"
              value={academic.programa || ''}
              onChange={(e) => handleChange('formacion_academica', 'programa', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Inicio"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={academic.fecha_inicio || ''}
              onChange={(e) => handleChange('formacion_academica', 'fecha_inicio', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Fin"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={academic.fecha_fin || ''}
              onChange={(e) => handleChange('formacion_academica', 'fecha_fin', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Lugar"
              value={academic.lugar || ''}
              onChange={(e) => handleChange('formacion_academica', 'lugar', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tesis"
              value={academic.tesis || ''}
              onChange={(e) => handleChange('formacion_academica', 'tesis', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('formacion_academica')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('formacion_academica')}>
          Guardar Formación Académica
        </Button>
      </Grid>
    </>
  );
};

export default AcademicFormation;
