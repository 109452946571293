// src/pages/Services.js

import React from 'react';

const Services = () => {
  return (
    <div>
      <h1>Services</h1>
      <p>Details about services we offer.</p>
    </div>
  );
};

export default Services;
