import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Recognitions = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const data = formData || [];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Reconocimientos
        </Typography>
        <Divider />
      </Grid>
      {data.map((recognition, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nombre del Reconocimiento"
              value={recognition.nombre_reconocimiento || ''}
              onChange={(e) => handleChange('reconocimientos', 'nombre_reconocimiento', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={recognition.fecha || ''}
              onChange={(e) => handleChange('reconocimientos', 'fecha', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('reconocimientos')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('reconocimientos')}>
          Guardar Reconocimientos
        </Button>
      </Grid>
    </>
  );
};

export default Recognitions;
