import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ActionAreas = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const data = formData || [];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Áreas de Actuación
        </Typography>
        <Divider />
      </Grid>
      {data.map((area, index) => (
        <Grid item xs={12} md={6} key={index}>
          <TextField
            fullWidth
            label="Área"
            value={area.area || ''}
            onChange={(e) => handleChange('areas_actuacion', 'area', e.target.value, index)}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('areas_actuacion')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('areas_actuacion')}>
          Guardar Áreas de Actuación
        </Button>
      </Grid>
    </>
  );
};

export default ActionAreas;
