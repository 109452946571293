import React, { useState } from 'react';
import { Box, Typography, TextField, Grid, Card, CardContent, Divider, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import JournalIcon from '@mui/icons-material/Book';
import ISSNIcon from '@mui/icons-material/Code';
import EditorIcon from '@mui/icons-material/Domain';
import VolumeIcon from '@mui/icons-material/LibraryBooks';
import PagesIcon from '@mui/icons-material/Pages';
import YearIcon from '@mui/icons-material/Event';
import DOIIcon from '@mui/icons-material/Link';
import InfoModal from '../components/modal'  // Importa el nuevo componente del modal
import logos from '../assets/logos.json'; // Asegúrate de que la ruta sea correcta

// Styled components for card and header
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.05)',
  },
  transition: 'transform 0.3s ease-in-out',
  backgroundColor: '#f9f9f9',
  height: '100%',
}));

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: theme.spacing(1),
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  transition: 'all 0.3s ease-in-out',
  height: '48px', // Ensure height to show two lines
  '&:hover': {
    whiteSpace: 'normal',
    overflow: 'visible',
    textOverflow: 'clip',
    backgroundColor: theme.palette.primary.dark,
    height: 'auto', // Ensure it expands to fit content
  },
}));

const ProduccionBibliografica = ({ productions, searchTerm, handleSearchChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', summary: '', pdfUrl: '', pdfLink: '' });

  const handleOpenModal = (info) => {
    setModalInfo(info);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const sortedProductions = [...productions].sort((a, b) => new Date(b.anio) - new Date(a.anio));
  const filteredProductions = sortedProductions.filter((production) =>
    production.titulo_articulo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getJournalData = (name) => {
    return logos.journals.find((journal) => journal.name.toLowerCase() === name.toLowerCase());
  };

  return (
    <Box className="page-content" sx={{ padding: 2 }}>
      <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>
        Producción Bibliográfica
      </Typography>

      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <TextField
          fullWidth
          label="Buscar por Título"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {filteredProductions.length > 0 ? (
          filteredProductions.map((production, index) => {
            const isEmpty = !production.tipo && !production.titulo_articulo && !production.revista && !production.issn && !production.editorial && !production.volumen && !production.fasciculo && !production.paginas && !production.anio && !production.doi;
            if (isEmpty) return null;

            const journalData = getJournalData(production.revista);

            const handleClick = () => {
              handleOpenModal({
                title: production.titulo_articulo,
                summary: `${production.revista}, ${production.issn}, ${production.editorial}, ${production.volumen}, ${production.fasciculo}, ${production.paginas}, ${production.anio}, ${production.doi}`,
                pdfUrl: production.pdfUrl || '',
                pdfLink: production.pdfLink || '',
              });
            };

            return (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <StyledCard onClick={handleClick}>
                  <TitleBox>
                    {production.titulo_articulo ? production.titulo_articulo.charAt(0).toUpperCase() + production.titulo_articulo.slice(1).toLowerCase() : ''}
                  </TitleBox>
                  <Divider />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      <JournalIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Revista:</strong> {production.revista ? production.revista.charAt(0).toUpperCase() + production.revista.slice(1).toLowerCase() : 'No hay datos'}
                      <br />
                      <ISSNIcon sx={{ verticalAlign: 'bottom' }} /> <strong>ISSN:</strong> {production.issn ? production.issn : 'No hay datos'}<br />
                      <EditorIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Editorial:</strong> {production.editorial ? production.editorial.charAt(0).toUpperCase() + production.editorial.slice(1).toLowerCase() : 'No hay datos'}<br />
                      <VolumeIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Volumen:</strong> {production.volumen ? production.volumen : 'No hay datos'}, <strong>Fascículo:</strong> {production.fasciculo ? production.fasciculo : 'No hay datos'}<br />
                      <PagesIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Páginas:</strong> {production.paginas ? production.paginas : 'No hay datos'}<br />
                      <YearIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Año:</strong> {production.anio ? production.anio : 'No hay datos'}<br />
                      <DOIIcon sx={{ verticalAlign: 'bottom' }} /> <strong>DOI:</strong> {production.doi ? production.doi : 'No hay datos'}<br />
                      
                      {journalData && (
                        <>
                          <br />
                          <a href={journalData.url} target="_blank" rel="noopener noreferrer">
                            <img
                              src={journalData.logo}
                              alt={production.revista}
                              style={{ height: 40, marginLeft: 10, verticalAlign: 'middle', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.2)' } }}
                            />
                          </a>
                        </>
                      )}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            );
          })
        ) : (
          <Typography variant="body1">No hay datos</Typography>
        )}
      </Grid>
      <InfoModal open={modalOpen} handleClose={handleCloseModal} info={modalInfo} />
    </Box>
  );
};

export default ProduccionBibliografica;
