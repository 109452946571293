export const toLowerCase = (text) => {
    if (typeof text !== 'string') return '';
    return text.toLowerCase();
};

// Función para convertir texto a mayúsculas
export const toUpperCase = (text) => {
    if (typeof text !== 'string') return '';
    return text.toUpperCase();
};

// Función para formatear fechas
export const formatDate = (date) => {
    if (!date) return 'No hay datos';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

// Función para normalizar un objeto de investigador
export const normalizeResearcher = (researcher) => {
    const defaultArray = ['No hay datos'];

    return {
        ...researcher,
        nombre: toUpperCase(researcher.nombre || 'No hay datos'),
        apellido: toUpperCase(researcher.apellido || 'No hay datos'),
        email: toLowerCase(researcher.email || 'No hay datos'),
        fechaNacimiento: formatDate(researcher.fechaNacimiento),
        // Normalizar otros campos según sea necesario
        informacion_personal: {
            ...researcher.informacion_personal,
            nombre_completo: toUpperCase(researcher.informacion_personal?.nombre_completo || 'No hay datos'),
            nombre_en_citaciones: toUpperCase(researcher.informacion_personal?.nombre_en_citaciones || 'No hay datos'),
            documento_identidad: researcher.informacion_personal?.documento_identidad || 'No hay datos',
            nacionalidad: toUpperCase(researcher.informacion_personal?.nacionalidad || 'No hay datos'),
            fecha_nacimiento: formatDate(researcher.informacion_personal?.fecha_nacimiento),
            lugar_nacimiento: toUpperCase(researcher.informacion_personal?.lugar_nacimiento || 'No hay datos'),
            sexo: toUpperCase(researcher.informacion_personal?.sexo || 'No hay datos'),
            foto: researcher.informacion_personal?.foto || 'https://via.placeholder.com/140',
        },
        redes_sociales_academicas: {
            google_scholar: researcher.redes_sociales_academicas?.google_scholar || 'No hay datos',
            researchgate: researcher.redes_sociales_academicas?.researchgate || 'No hay datos',
        },
        identificadores_de_autor: {
            autor_id_scopus: researcher.identificadores_de_autor?.autor_id_scopus || 'No hay datos',
            orcid: researcher.identificadores_de_autor?.orcid || 'No hay datos',
        },
        direccion_profesional: {
            institucion: toUpperCase(researcher.direccion_profesional?.institucion || 'No hay datos'),
            direccion_profesional: toUpperCase(researcher.direccion_profesional?.direccion_profesional || 'No hay datos'),
            barrio_profesional: toUpperCase(researcher.direccion_profesional?.barrio_profesional || 'No hay datos'),
            telefono_profesional: researcher.direccion_profesional?.telefono_profesional || 'No hay datos',
            email_institucional: toLowerCase(researcher.direccion_profesional?.email_institucional || 'No hay datos'),
        },
        direccion_residencial: {
            direccion_residencial: toUpperCase(researcher.direccion_residencial?.direccion_residencial || 'No hay datos'),
            barrio_residencial: toUpperCase(researcher.direccion_residencial?.barrio_residencial || 'No hay datos'),
            municipio: toUpperCase(researcher.direccion_residencial?.municipio || 'No hay datos'),
            telefono_residencial: researcher.direccion_residencial?.telefono_residencial || 'No hay datos',
            email_personal: toLowerCase(researcher.direccion_residencial?.email_personal || 'No hay datos'),
        },
        // Normalizar otros campos aquí
        formacion_academica: (researcher.formacion_academica || defaultArray).map(academic => ({
            nivel_academico: toUpperCase(academic.nivel_academico || 'No hay datos'),
            institucion: toUpperCase(academic.institucion || 'No hay datos'),
            programa: toUpperCase(academic.programa || 'No hay datos'),
            fecha_inicio: formatDate(academic.fecha_inicio),
            fecha_fin: formatDate(academic.fecha_fin),
            lugar: toUpperCase(academic.lugar || 'No hay datos'),
            tesis: academic.tesis || 'No hay datos',
        })),
        experiencia_profesional: (researcher.experiencia_profesional || defaultArray).map(exp => ({
            institucion: toUpperCase(exp.institucion || 'No hay datos'),
            dedicacion: exp.dedicacion || 'No hay datos',
            fecha_inicio: formatDate(exp.fecha_inicio),
            fecha_fin: formatDate(exp.fecha_fin),
        })),
        areas_actuacion: (researcher.areas_actuacion || defaultArray).map(area => ({
            area: toUpperCase(area.area || 'No hay datos'),
        })),
        idiomas: (researcher.idiomas || defaultArray).map(idioma => ({
            idioma: toUpperCase(idioma.idioma || 'No hay datos'),
            habla: toUpperCase(idioma.habla || 'No hay datos'),
            escribe: toUpperCase(idioma.escribe || 'No hay datos'),
            lee: toUpperCase(idioma.lee || 'No hay datos'),
            entiende: toUpperCase(idioma.entiende || 'No hay datos'),
        })),
        lineas_investigacion: (researcher.lineas_investigacion || defaultArray).map(linea => ({
            linea_investigacion: toUpperCase(linea.linea_investigacion || 'No hay datos'),
            activa: toUpperCase(linea.activa || 'No hay datos'),
        })),
        reconocimientos: (researcher.reconocimientos || defaultArray).map(reconocimiento => ({
            nombre_reconocimiento: toUpperCase(reconocimiento.nombre_reconocimiento || 'No hay datos'),
            fecha: formatDate(reconocimiento.fecha),
        })),
        trabajos_dirigidos_tutorias: (researcher.trabajos_dirigidos_tutorias || defaultArray).map(trabajo => ({
            tipo: toUpperCase(trabajo.tipo || 'No hay datos'),
            titulo: toUpperCase(trabajo.titulo || 'No hay datos'),
            institucion: toUpperCase(trabajo.institucion || 'No hay datos'),
            estado: toUpperCase(trabajo.estado || 'No hay datos'),
            especialidad: toUpperCase(trabajo.especialidad || 'No hay datos'),
            fecha: formatDate(trabajo.fecha),
            rol: toUpperCase(trabajo.rol || 'No hay datos'),
            orientados: Array.isArray(trabajo.orientados) ? trabajo.orientados.map(orientado => toUpperCase(orientado || 'No hay datos')) : defaultArray,
            tutores: Array.isArray(trabajo.tutores) ? trabajo.tutores.map(tutor => toUpperCase(tutor || 'No hay datos')) : defaultArray,
        })),
        eventos_cientificos: (researcher.eventos_cientificos || defaultArray).map(evento => ({
            nombre_evento: toUpperCase(evento.nombre_evento || 'No hay datos'),
            tipo_evento: toUpperCase(evento.tipo_evento || 'No hay datos'),
            ambito: toUpperCase(evento.ambito || 'No hay datos'),
            fecha_inicio: formatDate(evento.fecha_inicio),
            fecha_fin: formatDate(evento.fecha_fin),
            lugar: toUpperCase(evento.lugar || 'No hay datos'),
            instituciones_asociadas: Array.isArray(evento.instituciones_asociadas) ? evento.instituciones_asociadas.map(inst => ({
                nombre_institucion: toUpperCase(inst.nombre_institucion || 'No hay datos'),
                tipo_producto: toUpperCase(inst.tipo_producto || 'No hay datos'),
            })) : defaultArray,
            participantes: Array.isArray(evento.participantes) ? evento.participantes.map(part => ({
                nombre: toUpperCase(part.nombre || 'No hay datos'),
                rol_evento: toUpperCase(part.rol_evento || 'No hay datos'),
            })) : defaultArray,
        })),
        produccion_bibliografica: (researcher.produccion_bibliografica || defaultArray).map(prod => ({
            tipo_produccion: toUpperCase(prod.tipo_produccion || 'No hay datos'),
            autores: Array.isArray(prod.autores) ? prod.autores.map(autor => toUpperCase(autor || 'No hay datos')) : defaultArray,
            titulo_articulo: toUpperCase(prod.titulo_articulo || 'No hay datos'),
            revista: toUpperCase(prod.revista || 'No hay datos'),
            issn: prod.issn || 'No hay datos',
            editorial: toUpperCase(prod.editorial || 'No hay datos'),
            volumen: prod.volumen || 'No hay datos',
            numero: prod.numero || 'No hay datos',
            paginas: prod.paginas || 'No hay datos',
            fecha: formatDate(prod.fecha),
            doi: prod.doi || 'No hay datos',
        })),
    };
};
