import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAqC9LW8y6_XT8NPY7ajeMexOLsJgss6dM",
    authDomain: "elshaddai-2b5e4.firebaseapp.com",
    projectId: "elshaddai-2b5e4",
    storageBucket: "elshaddai-2b5e4.appspot.com",
    messagingSenderId: "152239013024",
    appId: "1:152239013024:web:d4929f964bb2c4770c5dc5",
    measurementId: "G-XQPZK0PYCM"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Configurar persistencia de autenticación
setPersistence(auth, browserLocalPersistence);

export { db, auth, storage };
