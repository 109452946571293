import React from 'react';
import { Box, Typography, Modal, Backdrop, Fade, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

// Styled components for modal
const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(4),
  boxShadow: theme.shadows[5],
  position: 'relative',
  width: '80%',
  maxWidth: '800px',
  maxHeight: '80vh',
  overflowY: 'auto',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: '"Times New Roman", Times, serif', // Estilo de tipografía de libro
  backgroundImage: 'url(/path/to/your/background/image.jpg)', // Añade un fondo
  backgroundSize: 'cover',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: theme.palette.grey[500],
}));

const PDFViewer = styled('iframe')({
  width: '100%',
  height: '500px',
  border: 'none',
  marginTop: '16px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

const InfoModal = ({ open, handleClose, info }) => {
  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <ModalContent>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {info.title}
          </Typography>
          <Divider />
          <Typography variant="body1" sx={{ mt: 2, lineHeight: 1.6 }}>
            {info.summary}
          </Typography>
          {info.pdfUrl && (
            <PDFViewer src={info.pdfUrl} />
          )}
          {!info.pdfUrl && info.pdfLink && (
            <Typography variant="body1" sx={{ mt: 2 }}>
              <a href={info.pdfLink} target="_blank" rel="noopener noreferrer">Ver PDF</a>
            </Typography>
          )}
        </ModalContent>
      </Fade>
    </StyledModal>
  );
};

export default InfoModal;
