import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Card, CardContent, Divider, IconButton } from '@mui/material';
import EventTypeIcon from '@mui/icons-material/Category';
import ScopeIcon from '@mui/icons-material/Public';
import StartDateIcon from '@mui/icons-material/CalendarToday';
import EndDateIcon from '@mui/icons-material/CalendarToday';
import LocationIcon from '@mui/icons-material/LocationOn';
import InstitutionIcon from '@mui/icons-material/School';
import ParticipantsIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';

import logos from '../assets/logos.json';
import InfoModal from './modal';

// Styled components for card and header
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.05)',
  },
  transition: 'transform 0.3s ease-in-out',
  backgroundColor: '#f9f9f9',
  height: '100%',
}));

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: theme.spacing(1),
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  transition: 'all 0.3s ease-in-out',
  height: '48px', // Ensure height to show two lines
  '&:hover': {
    whiteSpace: 'normal',
    overflow: 'visible',
    textOverflow: 'clip',
    backgroundColor: theme.palette.primary.dark,
    height: 'auto', // Ensure it expands to fit content
  },
}));

const EventosCientificos = ({ eventos, searchTerm, handleSearchChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', summary: '', pdfUrl: '', pdfLink: '' });

  const handleOpenModal = (info) => {
    setModalInfo(info);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const sortedEventos = [...eventos].sort((a, b) => new Date(b.fecha_inicio) - new Date(a.fecha_inicio));

  const filteredEventos = sortedEventos.filter((evento) =>
    evento.nombre_evento.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getInstitutionData = (name) => {
    const institution = logos.universities.find((uni) => uni.name.toLowerCase() === name.toLowerCase()) ||
      logos.hospitals.find((hospital) => hospital.name.toLowerCase() === name.toLowerCase());
    return institution;
  };

  return (
    <Box className="page-content" sx={{ padding: 2 }}>
      <Typography variant="h5" className="page-title" sx={{ backgroundColor: '#f0f0f0', padding: 1, borderRadius: 1, '&:hover': { backgroundColor: '#e0e0e0' } }}>
        Eventos Científicos
      </Typography>

      {/* Input de búsqueda */}
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <TextField
          fullWidth
          label="Buscar por Nombre del Evento"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {filteredEventos.length > 0 ? (
          filteredEventos.map((evento, index) => {
            const isEmpty = !evento.nombre_evento && !evento.tipo_evento && !evento.ambito && !evento.fecha_inicio && !evento.fecha_fin && !evento.lugar && evento.instituciones_asociadas.length === 0 && evento.participantes.length === 0;
            if (isEmpty) return null;

            const institutionData = getInstitutionData(evento.instituciones_asociadas[0]?.nombre_institucion);

            const handleClick = () => {
              handleOpenModal({
                title: evento.nombre_evento,
                summary: `${evento.tipo_evento}, ${evento.ambito}, ${new Date(evento.fecha_inicio).toLocaleDateString()} - ${new Date(evento.fecha_fin).toLocaleDateString()}, ${evento.lugar}, ${evento.instituciones_asociadas.map(inst => inst.nombre_institucion).join(', ')}, ${evento.participantes.map(part => part.nombre).join(', ')}`,
                pdfUrl: evento.pdfUrl || '',
                pdfLink: evento.pdfLink || '',
              });
            };

            return (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <StyledCard onClick={handleClick}>
                  <TitleBox>
                    {evento.nombre_evento ? evento.nombre_evento.charAt(0).toUpperCase() + evento.nombre_evento.slice(1).toLowerCase() : 'No hay datos'}
                  </TitleBox>
                  <Divider />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      <EventTypeIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Tipo de Evento:</strong> {evento.tipo_evento ? evento.tipo_evento.charAt(0).toUpperCase() + evento.tipo_evento.slice(1).toLowerCase() : 'No hay datos'}<br />
                      <ScopeIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Ámbito:</strong> {evento.ambito ? evento.ambito.charAt(0).toUpperCase() + evento.ambito.slice(1).toLowerCase() : 'No hay datos'}<br />
                      <StartDateIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Fecha de Inicio:</strong> {evento.fecha_inicio ? new Date(evento.fecha_inicio).toLocaleDateString() : 'No hay datos'}<br />
                      <EndDateIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Fecha de Fin:</strong> {evento.fecha_fin ? new Date(evento.fecha_fin).toLocaleDateString() : 'No hay datos'}<br />
                      <LocationIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Lugar:</strong> {evento.lugar ? evento.lugar.charAt(0).toUpperCase() + evento.lugar.slice(1).toLowerCase() : 'No hay datos'}<br />
                      <InstitutionIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Instituciones Asociadas:</strong> {evento.instituciones_asociadas.length > 0 ? evento.instituciones_asociadas.map(inst => inst.nombre_institucion).join(', ').toLowerCase() : 'No hay datos'}<br />
                      <ParticipantsIcon sx={{ verticalAlign: 'bottom' }} /> <strong>Participantes:</strong> {evento.participantes.length > 0 ? evento.participantes.map(part => part.nombre).join(', ').toLowerCase() : 'No hay datos'}
                      <br /><br />
                      {institutionData && (
                        <a href={institutionData.url} target="_blank" rel="noopener noreferrer">
                          <img
                            src={institutionData.logo}
                            alt={evento.instituciones_asociadas[0]?.nombre_institucion}
                            style={{ height: 32, marginLeft: 10, verticalAlign: 'middle', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.2)' } }}
                          />
                        </a>
                      )}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            );
          })
        ) : (
          <Typography variant="body1">No hay datos</Typography>
        )}
      </Grid>
      <InfoModal open={modalOpen} handleClose={handleCloseModal} info={modalInfo} />
    </Box>
  );
};

export default EventosCientificos;
