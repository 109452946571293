import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { db, auth, storage } from './firebaseConfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { toast } from 'react-toastify';
import { normalizeResearcher } from './services/utils';

// Crear thunk para obtener investigadores
export const fetchResearchers = createAsyncThunk('researchers/fetchResearchers', async () => {
  const querySnapshot = await getDocs(collection(db, 'researchers'));
  const researchers = [];
  querySnapshot.forEach((doc) => {
    researchers.push({ id: doc.id, ...doc.data() });
  });
  return researchers;
});

export const addResearcher = createAsyncThunk('researchers/addResearcher', async (newResearcher, { rejectWithValue }) => {
  try {
    let imageUrl = '';
    if (newResearcher.informacion_personal.foto) {
      const imageRef = ref(storage, `researchers/${new Date().getTime()}_${newResearcher.informacion_personal.foto.name}`);
      await uploadBytes(imageRef, newResearcher.informacion_personal.foto);
      imageUrl = await getDownloadURL(imageRef);
    }

    const researcherData = {
      ...newResearcher,
      informacion_personal: {
        ...newResearcher.informacion_personal,
        foto: imageUrl
      }
    };

    const docRef = await addDoc(collection(db, 'researchers'), researcherData);
    return { id: docRef.id, ...researcherData };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateResearcher = createAsyncThunk('researchers/updateResearcher', async ({ id, updatedResearcher }, { rejectWithValue }) => {
  try {
    const docRef = doc(db, 'researchers', id);
    const docSnapshot = await getDoc(docRef);
    const existingData = docSnapshot.data();
    
    // Combinar la sección actualizada con los datos existentes
    const updatedData = {
      ...existingData,
      ...updatedResearcher,
    };

    await updateDoc(docRef, updatedData);
    return { id, ...updatedData };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});


// Crear thunk para eliminar un investigador
export const deleteResearcher = createAsyncThunk('researchers/deleteResearcher', async (id, { rejectWithValue }) => {
  try {
    const docRef = doc(db, 'researchers', id);
    const docSnapshot = await getDoc(docRef);
    const imageUrl = docSnapshot.data().informacion_personal.foto;

    await deleteDoc(docRef);

    if (imageUrl) {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
    }

    return id;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Crear thunk para iniciar sesión
export const login = createAsyncThunk('auth/login', async ({ email, password }) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  toast.success('Successfully logged in!');
  return userCredential.user;
});

// Crear thunk para cerrar sesión
export const logout = createAsyncThunk('auth/logout', async () => {
  await signOut(auth);
  toast.success('Successfully logged out!');
});

// Verificar el estado de autenticación al cargar la aplicación
export const checkAuthState = createAsyncThunk('auth/checkAuthState', async (_, { dispatch }) => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
    });
  });
});

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: { currentPath: '/' },
  reducers: {
    setPath(state, action) {
      state.currentPath = action.payload;
    },
  },
});

const researcherSlice = createSlice({
  name: 'researchers',
  initialState: { list: [], status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResearchers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchResearchers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.list = action.payload;
      })
      .addCase(fetchResearchers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(`Error fetching researchers: ${action.error.message}`);
      })
      .addCase(addResearcher.fulfilled, (state, action) => {
        state.list.push(action.payload);
        toast.success('Researcher added successfully!');
      })
      .addCase(updateResearcher.fulfilled, (state, action) => {
        const index = state.list.findIndex((researcher) => researcher.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = action.payload;
          toast.success('Researcher updated successfully!');
        }
      })
      .addCase(deleteResearcher.fulfilled, (state, action) => {
        state.list = state.list.filter((researcher) => researcher.id !== action.payload);
        toast.success('Researcher deleted successfully!');
      });
  },
});

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        toast.error(`Login failed: ${action.error.message}`);
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.status = 'idle';
      })
      .addCase(checkAuthState.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

export const { setPath } = navigationSlice.actions;

const store = configureStore({
  reducer: {
    navigation: navigationSlice.reducer,
    researchers: researcherSlice.reducer,
    auth: authSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['auth.user'],
        ignoredActions: ['auth/login/fulfilled', 'auth/checkAuthState/fulfilled'],
      },
    }),
});

export default store;
