import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Languages = ({ formData, handleChange, handleAddField, handleSaveSection }) => {
  const data = formData || [];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Idiomas
        </Typography>
        <Divider />
      </Grid>
      {data.map((language, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Idioma"
              value={language.idioma || ''}
              onChange={(e) => handleChange('idiomas', 'idioma', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Habla"
              value={language.habla || ''}
              onChange={(e) => handleChange('idiomas', 'habla', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Escribe"
              value={language.escribe || ''}
              onChange={(e) => handleChange('idiomas', 'escribe', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Lee"
              value={language.lee || ''}
              onChange={(e) => handleChange('idiomas', 'lee', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Entiende"
              value={language.entiende || ''}
              onChange={(e) => handleChange('idiomas', 'entiende', e.target.value, index)}
            />
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('idiomas')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('idiomas')}>
          Guardar Idiomas
        </Button>
      </Grid>
    </>
  );
};

export default Languages;
