import React from 'react';
import { Grid, TextField, IconButton, Button, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const ScientificEvents = ({
  formData,
  setFormData,
  handleChange,
  handleAddField,
  handleSaveSection,
  jsonInput,
  handleJsonInput,
  handleProcessJson,
}) => {
  const data = formData || [];

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Eventos Científicos
        </Typography>
        <Divider />
      </Grid>
      {data.map((evento, index) => (
        <React.Fragment key={index}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Nombre del Evento"
              value={evento.nombre_evento || ''}
              onChange={(e) => handleChange('eventos_cientificos', 'nombre_evento', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Tipo de Evento"
              value={evento.tipo_evento || ''}
              onChange={(e) => handleChange('eventos_cientificos', 'tipo_evento', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Ámbito"
              value={evento.ambito || ''}
              onChange={(e) => handleChange('eventos_cientificos', 'ambito', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Inicio"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={evento.fecha_inicio || ''}
              onChange={(e) => handleChange('eventos_cientificos', 'fecha_inicio', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fecha de Fin"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={evento.fecha_fin || ''}
              onChange={(e) => handleChange('eventos_cientificos', 'fecha_fin', e.target.value, index)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Lugar"
              value={evento.lugar || ''}
              onChange={(e) => handleChange('eventos_cientificos', 'lugar', e.target.value, index)}
            />
          </Grid>
          {evento.instituciones_asociadas.map((institucion, instIndex) => (
            <React.Fragment key={instIndex}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Institución Asociada"
                  value={institucion.nombre_institucion || ''}
                  onChange={(e) =>
                    handleChange('eventos_cientificos', `instituciones_asociadas[${instIndex}].nombre_institucion`, e.target.value, index)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Tipo de Producto"
                  value={institucion.tipo_producto || ''}
                  onChange={(e) =>
                    handleChange('eventos_cientificos', `instituciones_asociadas[${instIndex}].tipo_producto`, e.target.value, index)
                  }
                />
              </Grid>
            </React.Fragment>
          ))}
          {evento.participantes.map((participante, partIndex) => (
            <React.Fragment key={partIndex}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nombre del Participante"
                  value={participante.nombre || ''}
                  onChange={(e) =>
                    handleChange('eventos_cientificos', `participantes[${partIndex}].nombre`, e.target.value, index)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Rol en el Evento"
                  value={participante.rol_evento || ''}
                  onChange={(e) =>
                    handleChange('eventos_cientificos', `participantes[${partIndex}].rol_evento`, e.target.value, index)
                  }
                />
              </Grid>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <IconButton onClick={() => handleAddField('eventos_cientificos')}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={6}
          label="Eventos Científicos JSON"
          value={jsonInput}
          onChange={handleJsonInput}
          placeholder="Ingresa el JSON aquí"
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleProcessJson}>
          Procesar JSON
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('eventos_cientificos')}>
          Guardar Eventos Científicos
        </Button>
      </Grid>
    </>
  );
};

export default ScientificEvents;
