import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store'; // Importa el store correctamente
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import './i18n'; // Importa la configuración de i18next

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
