import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './shared/pages/Home';
import About from './shared/pages/About';
import Services from './shared/pages/Services';
import Contact from './shared/pages/Contact';
import Login from './shared/pages/Login';

import ResearcherForm from './components/ResearcherForm';
import ResearcherDetails from './components/ResearcherDetails';

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<Login />} />
      <Route path="/researcher/:id" element={<ResearcherDetails />} />
      <Route path="/add-researcher" element={<ResearcherForm />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const NotFound = () => (
  <div>
    <h1>Page Not Found</h1>
  </div>
);

export default RoutesComponent;
