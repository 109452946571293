import React from 'react';
import { Grid, TextField, Button, Divider, Typography } from '@mui/material';

const SocialNetworks = ({ formData, handleChange, handleSaveSection }) => {
  const data = formData || {};

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Redes Sociales Académicas
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Google Scholar"
          value={data.google_scholar || ''}
          onChange={(e) => handleChange('redes_sociales_academicas', 'google_scholar', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="ResearchGate"
          value={data.researchgate || ''}
          onChange={(e) => handleChange('redes_sociales_academicas', 'researchgate', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={() => handleSaveSection('redes_sociales_academicas')}>
          Guardar Redes Sociales Académicas
        </Button>
      </Grid>
    </>
  );
};

export default SocialNetworks;
